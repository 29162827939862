export const USER_LOADED: string = 'USER_LOADED';
export const CATEGORY_FILTER_CHANGED: string = 'CATEGORY_FILTER_CHANGED';
export const CURRENT_MENY_ITEM: string = 'CURRENT_MENY_ITEM';

export const userLoaded = (user: any) => {
    return {
        type: USER_LOADED,
        payload: user,
    };
};

export const setCurrentMenuItem = (key: string) => {
    return {
        type: CURRENT_MENY_ITEM,
        payload: key,
    };
};

export const categoryFilterChanged = (reservationRoomFilter: any) => {
    console.log('categoryFilterChanged', reservationRoomFilter);
    return {
        type: CATEGORY_FILTER_CHANGED,
        payload: reservationRoomFilter,
    };
};
