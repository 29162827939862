import React from 'react';
import { Button, Form, Popover, Select, Input, Space } from 'antd';
import { PlusOutlined, MinusOutlined, UserOutlined } from '@ant-design/icons';
import { IReservationCompanyFilter } from '@entities/reservation-company-filter';
import { IReservationRoomFilter } from '@entities/reservation-room-filter';
import './guests.css';
interface IGuestsQty {
    filter: IReservationCompanyFilter | IReservationRoomFilter;
    className: string;
    form: any;
    setFilter: (value: any) => void;
    openPopover: boolean;
    handleOpenPopoverChange: any;
}

const GuestsControl = ({ ...props }: IGuestsQty) => {
    const { className, filter, setFilter, openPopover, handleOpenPopoverChange, form } = props;
    const ages: Array<any> = [];
    for (let i = 1; i <= 17; i++) ages.push({ value: i, label: i });

    const buttonLabel = () => {
        let adultLabel = filter.adultsQty === 1 ? 'взрослый' : 'взрослых';
        let childLabel = filter.childsQty === 1 ? 'ребенок' : 'детей';
        return `${filter.adultsQty} ${adultLabel} - ${filter.childsQty} ${childLabel}`;
    };

    const getboxGroups = () => {
        let ageControls: Array<any> = [];
        for (let index = 0; index < filter.childsQty; index++) {
            ageControls.push(
                <Space wrap={true} key={`child-${index}`}>
                    <Select
                        style={{ textAlign: 'center', width: 60 }}
                        className='age-selector'
                        options={ages}
                        onChange={(value) => {
                            let childAges2 = filter.childAges.slice(0);
                            childAges2[index] = value;
                            setFilter({ ...filter, childAges: childAges2 });
                        }}
                        defaultValue={filter.childAges[index]}
                    />
                </Space>
            );
        }

        return ageControls;
    };

    const okEnabled = filter.childsQty == 0 || filter.childAges.length >= filter.childsQty;

    const onClose = () => {
        if (filter.childsQty > filter.childAges.length) setFilter({...filter, childsQty : filter.childAges.length })
            else if (filter.childsQty < filter.childAges.length) setFilter({...filter, childAges: filter.childAges.slice(0,filter.childsQty)});
        handleOpenPopoverChange(false);
    }

    const onOpen = (e:boolean) => {
        //console.log("open", e);
        //handleOpenPopoverChange(e);
        e ? handleOpenPopoverChange(true) : onClose();
    }

    return (
        <Popover
            open={openPopover}
            onOpenChange={onOpen}
            trigger='click'
            className={className}
            content={
                <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 11 }} form={form} style={{ width: 300 }}>
                    <Form.Item label='Взрослых'>
                        <Input
                            style={{ textAlign: 'center' }}
                            addonAfter={<PlusOutlined onClick={() => {setFilter({ ...filter, adultsQty: filter.adultsQty + 1 });}}/>}
                            value={filter.adultsQty}
                            addonBefore={<MinusOutlined onClick={() => { if (filter.adultsQty > 1) setFilter({ ...filter, adultsQty: filter.adultsQty - 1 }); }}/>}
                        />
                    </Form.Item>
                    <Form.Item label='Детей'>
                        <Input
                            style={{ textAlign: 'center' }}
                            addonAfter={<PlusOutlined onClick={() => { setFilter({ ...filter, childsQty: filter.childsQty + 1 }); }}/>}
                            value={filter.childsQty}
                            addonBefore={<MinusOutlined onClick={() => { if (filter.childsQty > 0) setFilter({ ...filter, childsQty: filter.childsQty - 1 }); }} />}
                        />
                    </Form.Item>
                    {filter.childsQty > 0 && <Form.Item label='Возраст' wrapperCol={{ span: 16 }}>{getboxGroups()}</Form.Item>}
                    <Form.Item wrapperCol={{ span: 24 }}><Button className='guests-qty-btn' onClick={onClose} disabled={!okEnabled}>OK</Button></Form.Item>
                </Form>
            }
        >
            <Button className='guests-qty-container'>
                <UserOutlined />
                {buttonLabel()}
            </Button>
        </Popover>
    );
};

export default GuestsControl;