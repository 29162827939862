import PaymentPageSdk from '@raiffeisen-ecom/payment-sdk';
import {message} from 'antd';
//import { Client, ClientException } from '@raiffeisen-ecom/payment-sdk';
// https://github.com/Raiffeisen-DGTL/ecom-sdk-javascript

const Raif = (amount, orderId, comment, successSbpUrl) => {
    const publicId = process.env.REACT_APP_RAIF_PUBLIC_ID;
    //const publicId = "MA0000839716";
    //const publicId = process.env.RAIF_CODE;
    const paymentPage = new PaymentPageSdk(publicId);
    //const options = {amount, orderId, comment, successSbpUrl};
    const options = {amount, orderId, comment, successSbpUrl};
    console.log(options);
    paymentPage.openPopup(options)
        .then(function() {
            console.log("Спасибо");
            message.success("Оплата успешно совершена")
            window.location.reload();
        })
        .catch(function() {
            console.log("Неудача");
            message.error("Ошибка оплаты")
        });
}

export default Raif;