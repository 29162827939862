import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Schema from 'async-validator';

import App from './App';
import { CookiesProvider } from 'react-cookie';
import { store, persistor } from '@store/store';

import { ConfigProvider, theme } from 'antd';

import 'dayjs/locale/ru';
import ruRU from 'antd/es/locale/ru_RU';

const dayjs = require('dayjs');
dayjs.locale('ru');

Schema.warning = function () {};
const { defaultAlgorithm } = theme;

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <CookiesProvider>
                <ConfigProvider
                    locale={ruRU}
                    theme={{
                        algorithm: defaultAlgorithm,
                        token: {
                            colorPrimary: '#B42F32',
                            colorText: '#426F8C',
                            fontFamily: 'Open Sans',
                            //fontFamily: 'Oswald Variable',
                        },
                    }}
                >
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ConfigProvider>
            </CookiesProvider>
        </PersistGate>
    </Provider>
);
