import React from "react";
import SearchHomeFilter from "./search-home-filter";
import MainHeader from "../main/main-header";
import MainFooter from "../main/main-footer";
import './search-home.css';

const SearchHome = () => {
    return<>
    <MainHeader />
    <div className="search-home-main" style={{ background: "url(\"/images/search-bg.png\") no-repeat", backgroundPosition: "top center" }}>        
        <SearchHomeFilter />        
    </div>
    <MainFooter hidden={false}/>
    </>
}

export default SearchHome;