import React from "react"
import {weekDayToStr} from '../../extensions/utils';
import dayjs from "dayjs";
import './category-var-day.css';

const CategoryVarDay = ({...props}) => {
    const {weekDay, date, amount, selected, onSelect, disabled} = props;
    const mainClassName = (disabled ? "category-var-day-disabled category-var-day" : selected ? "category-var-day-selected category-var-day-enabled" : "category-var-day category-var-day-enabled") + " category-var-day";

    return <div className={mainClassName} onClick={() => { if (!disabled) onSelect(weekDay) }}>
        <div className="category-var-day-text">{weekDayToStr(weekDay)}</div>
        <div className="category-var-day-text">{dayjs(date).format("DD.MM")}</div>
        <div className="category-var-day-text">{amount > 0 && amount.toLocaleString('ru-RU') + " ₽"}</div>
    </div>
}

export default CategoryVarDay;