import React, { useEffect, useRef, useState } from "react";
import './search-region-item.css';
import SearchRegionVar from "./search-region-var";
import SearchRegionToggle from "./search-region-toggle";
import { serverFetch } from "@src/server";
import { exception } from "@src/extensions/notification";
import { Button, Carousel, Skeleton, Tooltip, notification } from "antd";
import dayjs from "dayjs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useWindowWidth } from "@react-hook/window-size";

const SearchRegionItem = ({...props}) => {
    const { item, companyId, filter, extraFilter } = props;
    const [collapsed, setCollapsed] = useState(true);
    const [company, setCompany] = useState();
    const [loading, setLoading] = useState(false);
    const [api, _] = notification.useNotification();
    const [infras, setInfras] = useState([]);
    const [images, setImages] = useState([]);
    const isMobile = useWindowWidth() <= 992;
    const ref = useRef(null);    
    const getInfra = () => {
        serverFetch(`companies/Infrastructure/${companyId}`, { method: 'GET' })
            .then((data) => setInfras(data))
            .catch((ex) => exception(api, 'Ошибка получения инфраструктуры', ex));
    }
    const getImages = () => {
        serverFetch(`companies/Gallery/${companyId}`, { method: 'GET' })
            .then((data) => setImages(data))
            .catch((ex) => exception(api, 'Ошибка получения фото', ex));
    }
    useEffect(() => {
        setLoading(true);
        getInfra();
        getImages();
        serverFetch(`companies/${companyId}`, {method: 'GET'})
            .then((data) => {
                setCompany(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения компании', ex);
            });
    }, [])
    const divVars = <div>{item.rooms && item.rooms.map((room,idx) => 
        (!collapsed || idx == 0) ? <SearchRegionVar item={room} key={" " + room.roomCategoryName + room.tariffName} companyId={companyId} filter={filter}/> : null)}</div>
    // Другие варианты размещения
    const divExpanded = <SearchRegionToggle collapsed={collapsed} onCollaps={() => setCollapsed(!collapsed)} qty={item.rooms.length - 1}/> 
    // Сейчас просматривают
    const qtyLook = company ? company.activeUserCount : 0;
    const divLookers = qtyLook > 0 && (
        <div className='search-categoies-filter-users'>
            <img src='/images/viewers.svg' />
            <div>
                Сейчас просматривают {qtyLook}{' '}
                {(qtyLook < 10 || qtyLook > 20) && qtyLook % 10 > 1 && qtyLook % 10 < 5 ? 'человека' : 'человек'}{' '}
            </div>
        </div>
    );
    // Последнее бронирование
    const dd = company?.lastOrder?.orderDateTime + '+03:00';
    const lastOrder = company && company.lastOrder ? dayjs().diff(dayjs(dd), 'hours') : 0;
    const divLastBook = companyId && lastOrder < 2400 && (
        <div className='search-categoies-filter-users'>
            <img src='/images/bookers.svg' />
            {lastOrder < 24 &&<div>Последний раз бронировали {lastOrder == 0 ? 'несколько минут назад' : `${lastOrder} часов назад`}</div>}
        </div>
    );
    // Инфраструктура
    const divInfra = (
        <div className='category-vars-category-infra'>
            {infras.map((e) => <Tooltip title={e.name} key={e.infrastructureID}>
                    <img src={`${process.env.REACT_APP_API_BASE_URL}/api/image${e.imageFileName}`} key={e.infrastructureID}/>
                </Tooltip>)}
        </div>
    );
    // Картинки
    const SlickArrowLeft = () => <Button aria-hidden='true' shape='circle' icon={<LeftOutlined />} className="slick-arrow slick-prev" onClick={() => ref.current?.prev()}/>
    const SlickArrowRight = () => <Button aria-hidden='true' shape='circle' icon={<RightOutlined />} className="slick-arrow slick-next" onClick={() => ref.current?.next()}/>
    const divImages = images.length == 0 ? <Skeleton.Image active /> : <Carousel arrows={true} prevArrow={<SlickArrowLeft />} nextArrow={<SlickArrowRight />} slidesToScroll={1} ref={ref}>
            {images.map((i) => {
                const fileName = `${process.env.REACT_APP_API_BASE_URL}/api/image${i.imageFileName}`;
                return <div key={i.imageId}>
                        <div className='category-vars-image'>
                            <img style={{ width: 360 }} src={fileName} />
                        </div>
                    </div>
            })}
        </Carousel>
    const divTitle = <div className="search-region-item-company-name">{item.company.companyName}</div>
    const onBook = () => {
        const url = `/booking/search/category/${companyId}`;
        window.location.href = url;
    }
    const divAddress = <div className="search-region-item-address">{item.company?.address}</div>
    const hasFilter = () => {
        if (!extraFilter) return true;
        // Фильтр по инфраструктуре
        let ok = true;
        if (extraFilter.infras && extraFilter.infras.length > 0){
            extraFilter.infras.forEach(infrastructureID => {
                ok = ok && infras.find(i => i.infrastructureID == infrastructureID);
            });
        }
        // Фильтр по месту размещения
        if (extraFilter.places && extraFilter.places.length > 0 && item?.company?.placeId){
            if (extraFilter.places.findIndex(i => i == item?.company?.placeId) == -1) return false;
        }
        return ok;
    }
    //console.log(item);
    return hasFilter() ? <div className="search-region-item">
        <div className="search-region-item-main">
            {isMobile && divTitle}
            <div className="search-region-item-img">
                {divImages}
            </div>
            <div className="search-region-item-main-text">
                {!isMobile && divTitle}
                {divAddress}
                <div className="search-region-item-stat">{divLookers}{divLastBook}</div>
                {divInfra}
                <div className="search-region-item-company-desc">{item.company.description}</div>
                <Button className="search-region-var-action" style={{width: 200, marginTop: 4}} onClick={onBook}>Выбрать категорию</Button>
            </div>
        </div>
        {divVars}
        {divExpanded}
    </div> : <div />
}

export default SearchRegionItem;