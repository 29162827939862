import { serverFetch } from "@src/server";
import { Button, Checkbox, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import './search-region.css';
import MainHeader from "../main/main-header";
import MainFooter from "../main/main-footer";
import { GuestsControl, Spinner } from "@src/controls";
import SearchRegionItem from "./search-region-item";
import { useWindowWidth } from '@react-hook/window-size';
import { isStringNumber, withoutTime } from "@src/extensions/utils";
import Maps from "../maps/maps";
import LocalFuncs from "@src/extensions/local-funcs";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@src/store/hooks";
import { categoryFilterChanged } from "@src/store/actions";
import { CloseOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

const SearchRegion = () =>{
    const { regionAlias } = useParams();
    const [region, setRegion] = useState();
    const [filter, setFilter] = useState(LocalFuncs.checkFilterPeriod(useSelector((s) => s.reservationRoomFilter)));
    const [openPopover, setOpenPopover] = useState(false);
    const [searching, setSearching] = useState(false);
    const [itemsP, setItemsP] = useState([]);
    const [itemsA, setItemsA] = useState([]);
    const [firstStart, setFirstStart] = useState(true);
    const [extraFilter, setExtraFilter] = useState({places: [], rooms: [], infras: []});
    const [regionInfo, setRegionInfo] = useState();
    const [showMap, setShowMap] = useState(false);
    const [regLoading, setRegLoading] = useState(false);
    const [showMobileFilter, setShowMobileFilter] = useState(false);
    const isMobile = useWindowWidth() <= 992;
    const d = useAppDispatch();

    const filterSelected = extraFilter && (extraFilter.places.length > 0 || extraFilter.rooms.length > 0 || extraFilter.infras.length > 0)
    const getRegionData = (regionID) => serverFetch(`region/RegionInfo/${regionID}`, { method: 'GET' })
        .then((data) => {
            //console.log(data);
            document.title = data.metaTitle;            
            setRegionInfo(data);
            setRegLoading(false);
        })
        .catch((e) => {
            console.log("Ошибка получения информации о регионе", e);
            setRegLoading(false);
        })
    const getItem = () => {
        setRegLoading(true);
        serverFetch(`region/${regionAlias}`, { method: 'GET' })
            .then(data => {
                setRegion(data);
                getRegionData(data.regionID);                
            })
            .catch((e) => {
                console.log(`Ошибка региона ${e.userMessage}`);
                setRegLoading(false);
            });
        }
    const onChangeExtraFilter = (name, id, value) => {
        const newExtraFilter = {...extraFilter};
        if (name == 'place') {
            const idx = newExtraFilter.places.indexOf(id);
            if (value && idx == -1) newExtraFilter.places.push(id);
            if (!value && idx >= 0) newExtraFilter.places.splice(idx, 1);
        }
        else if (name == 'room') {
            const idx = newExtraFilter.rooms.indexOf(id);
            if (value && idx == -1) newExtraFilter.rooms.push(id);
            if (!value && idx >= 0) newExtraFilter.rooms.splice(idx, 1);
        }
        else if (name == 'infra') {
            const idx = newExtraFilter.infras.indexOf(id);
            if (value && idx == -1) newExtraFilter.infras.push(id);
            if (!value && idx >= 0) newExtraFilter.infras.splice(idx, 1);
        }        
        setExtraFilter(newExtraFilter);
    }
    useEffect(getItem, [regionAlias]);
    const onSearch = () => {
        d(categoryFilterChanged(filter))
        setSearching(true);
        const calcAsk = {
            companyId: "0",
            adults: Number(filter.adultsQty),
            arrival: filter.arrivalDate.utc(true).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
            departure: filter.departureDate.utc(true).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
            tariffId: [],
            tarifs: [],
            userType: 0,
            childrenAges: filter.childAges?.slice(0, filter.childsQty),
            orderTimeId: 0, 
            regionId: region.regionID
        };

        serverFetch(`calc/GetAvailableRoomsRegion`, { method: 'POST', bodyData: calcAsk })
            .then((data) => {
                setItemsP(data);
                setSearching(false);
            })
            .catch((e) => {
                setSearching(false);
                console.log(`Ошибка получения вариантов размещения рртур. ${e.userMessage || ''} `);
            });
        serverFetch(`calc/AleanGetAvailableRoomsRegion`, { method: 'POST', bodyData: calcAsk })
            .then((data) => {
                setItemsA(data);
                setSearching(false);
            })
            .catch((e) => {
                setSearching(false);
                console.log(`Ошибка получения вариантов размещения алеан. ${e.userMessage || ''} `);
            });
    };
    useEffect(() => {
        if (firstStart && region && filter) {
            onSearch();
            setFirstStart(false);
        }
    }, [region, filter]);
    const divPlaces = region && region.placements && <>
        <div className="search-region-filter-title">Места отдыха</div>
        {region.placements.map((item) => <Checkbox
            key={item.placeID} className="search-region-filter-item" 
            onClick={(e) => onChangeExtraFilter('place', item.placeID, e.target.checked)}
            checked={extraFilter && extraFilter.places.indexOf(item.placeID) >= 0}
        >{item.name}</Checkbox>)}
    </>
    // const divCategories = region && region.baseRoomTypes && <>
    //     <div className="search-region-filter-title">Номера</div>
    //     {region.baseRoomTypes.map((item) => <Checkbox 
    //         key={item.baseRoomTypeID} className="search-region-filter-item"
    //         onClick={(e) => onChangeExtraFilter('room', item.baseRoomTypeID, e.target.checked)}
    //         checked={extraFilter && extraFilter.rooms.indexOf(item.baseRoomTypeID) >= 0}
    //     >{item.name}</Checkbox>)}
    // </>
    const divInfra = region && region.infrastructure && <>
        <div className="search-region-filter-title">Инфраструктура</div>
        {region.infrastructure.filter(item => item.isMain).map((item) => <Checkbox
            key={item.infrastructureID} className="search-region-filter-item"
            onClick={(e) => onChangeExtraFilter('infra', item.infrastructureID, e.target.checked)}
            checked={extraFilter && extraFilter.infras.indexOf(item.infrastructureID) >= 0}
        >{item.name}</Checkbox>)}
    </>
    const divSearching = searching && <div>Поиск...</div>
    const items = [...itemsP, ...itemsA];
    const coords = [];
    const onBook = (companyId) => window.location.href = `/booking/search/category/${companyId}`
    items.forEach(item => {
        const c = item.company;
        const price = item.rooms.length == 0 ? "" : item.rooms[0].amount.toLocaleString('ru-RU');
        if (c.gpsLatitude && c.gpsLongitud && isStringNumber(c.gpsLatitude) && isStringNumber(c.gpsLongitud))
          coords.push({name: c.gpsName || c.companyName, x: parseFloat(c.gpsLatitude), y: parseFloat(c.gpsLongitud), price, companyId: c.companyId });
    });
    const divResults = !searching && items.map(item => <SearchRegionItem item={item} key={item.company.companyId} companyId={item.company.companyId} filter={filter} extraFilter={extraFilter}/>)
    const disabledDate = (current) => current && dayjs(current) < withoutTime(dayjs())
    const disabledDateEnd = (current) => current && (dayjs(current) < withoutTime(dayjs()) || current < filter.arrivalDate)
    const onChangeDateBeg = (arrivalDate) => {
        const srok = filter.departureDate.diff(filter.arrivalDate, 'days');
        const departureDate = arrivalDate.add(srok, 'days');
        setFilter({...filter, arrivalDate, departureDate})
    }
    const onChangeDateEnd = (departureDate) => setFilter({...filter, departureDate})
    const divSearch = filter && <>
        <div className="search-region-filter-item">
            <div className="search-region-filter-item-label">Заезд</div>
            <DatePicker format={'DD.MM.YYYY'} allowClear={false} variant="borderless" suffixIcon={null} className="search-region-filter-item-date" 
                value={filter.arrivalDate} 
                onChange={onChangeDateBeg}
                disabledDate={disabledDate}/>
        </div>
        <div className="search-region-filter-item">
            <div className="search-region-filter-item-label">Выезд</div>
            <DatePicker format={'DD.MM.YYYY'} allowClear={false} variant="borderless" suffixIcon={null} className="search-region-filter-item-date" 
                value={filter.departureDate} onChange={onChangeDateEnd}
                disabledDate={disabledDateEnd}/>
        </div>
        <div className="search-region-filter-item">
            <GuestsControl filter={filter} setFilter={setFilter} openPopover={openPopover} handleOpenPopoverChange={setOpenPopover} className="search-region-guest"/>
        </div>
        <div className="search-region-filter-item"><Button className="search-region-button" onClick={onSearch} disabled={!region}>Найти</Button></div>
    </>
    const articles = [];
    if (regionInfo && regionInfo.articleTitle1) articles.push({title: regionInfo.articleTitle1, url: regionInfo.articleURL1});
    if (regionInfo && regionInfo.articleTitle2) articles.push({title: regionInfo.articleTitle2, url: regionInfo.articleURL2});
    if (regionInfo && regionInfo.articleTitle3) articles.push({title: regionInfo.articleTitle3, url: regionInfo.articleURL3});
    const divBtns = regLoading || articles.map((item, idx) => <Button key={`btn-${idx}`} onClick={() => item.url && window.open(`/article/${encodeURIComponent(item.url)}`, '_blank').open()}>{item.title}</Button>);
    const divMaps = showMap && <Maps onClose={() => setShowMap(false)} coords={coords} onBook={onBook}/>

    const divMobileFitler = <div className="search-region-mobile-filter">
        <div className="search-region-mobile-filter-title"><span>Фильтр</span><CloseOutlined onClick={() => setShowMobileFilter(false)}/></div>
        {divPlaces}
        {divInfra}
    </div>
    const divTite = isMobile 
        ? <div>
            <div className='search-region-title'>{region && region.regionName}</div>
            <div className='search-region-title' style={{padding: 0, marginBottom: 4, columnGap: 4, display: "flex"}}>
                {items && items.length > 0 && <Button onClick={() => setShowMap(!showMap)}>Показать на карте</Button>}
                <Button onClick={() => setShowMobileFilter(true)} className={filterSelected ? "search-region-mobile-filter-selected" : ""}>Фильтр</Button>
            </div>
          </div>
        :<div className='search-region-title'>Отдых в регионе {region && region.regionName}{items && items.length > 0 && 
            <span style={{marginLeft: 8}}><Button onClick={() => setShowMap(true)}>Показать на карте</Button></span>}
        </div>
    console.log(regionInfo);
    const divMeta = regionInfo && <Helmet>
        <meta charSet='urf-8' />
        {regionInfo.metaDescription && <meta name='description' content={regionInfo.metaDescription} />}
        {regionInfo.metaKeywords && <meta name='keywords' content={regionInfo.metaKeywords} />}
    </Helmet>
    return<>
        {divMeta}
        <MainHeader />
        {isMobile && showMobileFilter && divMobileFitler}
        <div className='search-region-container'>
            {filter && isMobile ? divSearch : <div className="search-region-left">
                {divSearch}
                {divPlaces}
                {divInfra}
            </div>}
            <div className="search-region-right">
                {regLoading ? <Spinner /> : divTite}
                <div className="image-container">{region && <img src={"data:image/png;base64, " + (region && (!isMobile && region.imageDesktop ? region.imageDesktop : region.image))} />}</div>
                <div className="search-region-review">{region && region.review}</div>
                <div className="search-region-articles">{divBtns}</div>
                <div className="search-region-results">
                    {divSearching}
                    {divResults}
                </div>
            </div>
        </div>
        <MainFooter hidden={false}/>
        {divMaps}
    </>
}

export default SearchRegion;