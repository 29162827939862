import React, { useRef, useState } from 'react';
import { ISearchAvailRoom } from '@entities/search-avail-room';
import { Carousel, Button, Tooltip, Skeleton } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { IImage } from '@src/store/entities/image';
import { IInfrastructure } from '@src/store/entities/infrastructure';
import './category-vars.css';
import { CarouselRef } from 'antd/es/carousel';

// Карточка категории в поиске доступных категорий
const CategoryVars = ({ ...props }) => {
    const { category, items, bestPrice, selected, onSelectCategory, images, infras, id, hasChoice } = props;
    const [showDescription, setShowDescription] = useState<Boolean>(false);
    const ref = useRef<CarouselRef>(null);
    let divMen = [];
    for (let i = 0; i < category.countMainBeds; i++) divMen.push(<img src='/images/man.png' key={`m${i}`} />);
    if (category.countExtraBeds > 0) {
        divMen.push(<img src='/images/plus.png' key={'p'} />);
        for (let i = 0; i < category.countExtraBeds; i++) divMen.push(<img src='/images/man.png' key={`e${i}`} />);
    }
    const seatHint = `Основных мест - ${category.countMainBeds}` + (category.countExtraBeds > 0 ? `, доп. ${category.countExtraBeds}` : '');
    const hasDiscount = items.find((e: ISearchAvailRoom) => e.amount != e.amountWithDiscount) != null;
    const divDiscount = hasDiscount ? (
        <div className='category-vars-category-img-discount'>
            <img src='/images/online_skidka_red_m.png' />
        </div>
    ) : null
    const divBest = (
        <div className='category-vars-category-img-best'>
            {bestPrice && <img src='/images/best_tariff_116.png' />}
            {category.qtyRest == 1 && <img src='/images/vsego_1nomer_116.png' />}
            {category.qtyRest == 2 && <img src='/images/vsego_2nomera_116.png' />}
            {category.salesLeader && <img src='/images/lider_prodazh_116.png' />}
        </div>
    )
    //const fileName = `${process.env.REACT_APP_API_BASE_URL}/api/image${i.imageFileName}`;
    const divInfra = (
        <div className='category-vars-category-infra'>
            {infras.map((e: IInfrastructure) => (
                <Tooltip title={e.name} key={e.infrastructureID}>
                    <img src={`${process.env.REACT_APP_API_BASE_URL}/api/image${e.imageFileName}`} key={e.infrastructureID} />
                </Tooltip>
            ))}
        </div>
    )
    // Получить минимальный тариф
    const divNoDiscount =
        category.amount == category.amountWithDiscount ? null : (
            <div className='category-vars-category-bottom-red'>{category.amount} ₽</div>
        );
    const divBottom = (
        <div className='category-vars-category-bottom'>
            {category.amount > 0 ? (
                <div className='category-vars-category-bottom-price'>
                    {divNoDiscount}
                    {`${hasChoice ? 'от' : ''} ${category.amountWithDiscount.toLocaleString('ru-RU')} ₽`}
                </div>
            ) : null}
            <Button
                className={'category-vars-category-bottom-btn' + (items.length == 0 ? ' width-100-prc' : '')}
                onClick={() => onSelectCategory(category.roomCategoryId)}
            >
                {items.length == 0 ? 'ДОСТУПНЫЕ ДАТЫ' : 'ЗАБРОНИРОВАТЬ'}
            </Button>
        </div>
    )
    //console.log(infras);
    const SlickArrowLeft = () => <Button aria-hidden='true' shape='circle' icon={<LeftOutlined />} className="slick-arrow slick-prev" onClick={() => ref.current?.prev()}/>
    const SlickArrowRight = () => <Button aria-hidden='true' shape='circle' icon={<RightOutlined />} className="slick-arrow slick-next" onClick={() => ref.current?.next()}/>
    const toggleShowDescription = () => setShowDescription(!showDescription)
    const divShowDescription = showDescription ? 
          <div className='category-vars-show-description-img'><img src='/images/down.svg' style={{cursor: "pointer"}}/></div>
        : <div className='category-vars-show-description-img'><img src='/images/up.svg' style={{cursor: "pointer"}}/></div>
    return <div className={`category-vars-container ${selected && 'category-vars-container-selected'}`} id={id}>
            <div className='category-vars-category-img'>
                {images.length == 0 ? <Skeleton.Image active /> : <Carousel arrows={true} prevArrow={<SlickArrowLeft />} nextArrow={<SlickArrowRight />} slidesToScroll={1} ref={ref}>
                        {images.map((i: IImage) => {
                            const fileName = i.imageFileName ? `${process.env.REACT_APP_API_BASE_URL}/api/image${i.imageFileName}` : i.source;
                            return <div key={i.imageId}>
                                    <div className='category-vars-image'>
                                        <img style={{ width: 360 }} src={fileName} />
                                    </div>
                                </div>
                        })}
                    </Carousel>
                }
                {divDiscount}
                {divBest}
            </div>
            <div className='category-vars-content'>
                <div>
                    <div className='category-vars-category-title' onClick={toggleShowDescription}>
                        <span style={{ maxWidth: 275, cursor: "pointer" }}>{category.name}</span>
                        <span><div className='category-vars-show-description'><Tooltip title={seatHint}>{divMen}</Tooltip>{divShowDescription}</div></span>
                    </div>
                    {showDescription && <div className='category-vars-category-description'>{category.description}</div>}
                    {divInfra}
                </div>
                {selected ? null : divBottom}
            </div>
        </div>
};

export default CategoryVars;