import React from "react";
import './search-region-var.css';
import { Button } from "antd";
import { useWindowWidth } from "@react-hook/window-size";

const SearchRegionVar = ({...props}) => {
    const {item, companyId} = props;
    const isMobile = useWindowWidth() <= 992;

    //console.log(item);
    const onBook = () => {
      const url = `/booking/order/${companyId}?pricePeriodId=${item.tariffExtraId || item.roomPricePeriodId}&roomCategoryId=${item.roomCategoryId}`;
      window.location.href = url;
    }

    if (isMobile)
      return<div className="search-region-var">
        <div>
            <span className="search-region-var-title">{item.roomCategoryName}</span>
            <span className="search-region-var-tarif">{item.tariffName}</span>
        </div>
        <div>
            <div className="search-region-var-price">{item.amount.toLocaleString('ru-RU')} руб.</div>
            <Button className="search-region-var-action" onClick={onBook}>БРОНИРОВАТЬ</Button>
        </div>
      </div>

    return <div className="search-region-var">
        <div className="search-region-var-title">{item.roomCategoryName}</div>
        <div className="search-region-var-tarif">{item.tariffName}</div>
        <div className="search-region-var-price">{item.amount.toLocaleString('ru-RU')} руб.</div>
        <Button className="search-region-var-action" onClick={onBook}>БРОНИРОВАТЬ</Button>
    </div>
}

export default SearchRegionVar;