import { GetSeatName } from "@src/extensions/alean-opers";
import React from "react";
import { Tooltip } from "antd";

const AleanPlaces = ({...props}) => {
    const {aleanRoomPlacing, aleanTouristTypes} = props;
    const items = <ul>{aleanRoomPlacing.roomTouristPlacing.map((i,idx) => {

        const ttype = aleanTouristTypes.find(e => e.touristTypeID == i.touristTypeID);

        return <li key={idx} className="place">
            <div>{ttype.touristTypeName} - {GetSeatName(i.placeKind)} {i.quantity == 1 ? "" : ` x ${i.quantity}`} </div>
        </li>
    })}</ul>
    return <Tooltip title={items}><div style={{cursor: "pointer"}}>{aleanRoomPlacing.roomPlacingName}</div></Tooltip>
}

export default AleanPlaces;