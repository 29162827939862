import moment from 'moment';
import { IOrderStatus } from '@src/store/enums/order-status';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export function tie(changeFunction, name) {
    return {
        value: this.props && this.props[name],
        onChange: (v) => changeFunction(name, v),
        error: this.props && this.props.errors && this.props.errors[name],
    };
}

export function newtie(changeFunction, name) {
    return {
        value: this.props && this.props[name],
        name: name,
        onChange: changeFunction,
        error: this.props && this.props.errors && this.props.errors[name],
    };
}

export function formatSum(s) {
    if (isNaN(s)) s = 0;
    return s.toLocaleString(undefined, { minimumFractionDigits: 2 });
}

export function getRouterParameter(props, name, toObject = false) {
    const v = props.match && props.match.params && props.match.params[name];
    return toObject && v ? JSON.parse(decodeURIComponent(v)) : v;
}

export function sanitize(text, allowedTags = ['b', 'i', 'u', 'br', 'br', 'div']) {
    return (
        text &&
        text.replace(/<[^>]*>/gm, (tag, a, b, c, d) => {
            let at = allowedTags.find((x) => tag.startsWith('<' + x));
            if (at) return '<' + at + '>';
            at = allowedTags.find((x) => tag === '</' + x + '>');
            if (at) return tag;
            return '';
        })
    );
}

export function nvl(a, b) {
    return a === null || a === undefined ? b : a;
}

export function toUrl(o) {
    return encodeURIComponent(JSON.stringify(o));
}

export function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
}

export function flattenArray(arr1) {
    return arr1.reduce((arr, val) => (Array.isArray(val) ? arr.concat(flattenArray(val)) : arr.concat(val)), []);
}

export function dateToServerFormat(d) {
    return d ? moment(d).locale('en').format('YYYY-MM-DD') + 'T00:00:00.000Z' : null;
}

export function getErrorTitleFromHtml(htmlText) {
    if (!htmlText || !htmlText.startsWith || !htmlText.startsWith('<!DOCTYPE html>')) {
        return null;
    }

    let htmlError = 'Server error: ';
    let startIdx = htmlText.indexOf('<h1>') + 4;
    let endIdx = htmlText.indexOf('</h1>', startIdx);
    htmlError = htmlError + htmlText.substring(startIdx, endIdx);
    startIdx = htmlText.indexOf('<div class="titleerror">', endIdx) + 24;
    endIdx = htmlText.indexOf('</div>', startIdx);
    htmlError = htmlError + ' ' + htmlText.substring(startIdx, endIdx);
    return htmlError;
}

export function distinct(arr) {
    let distinctArray = [];
    arr.forEach((x) => !distinctArray.includes(x) && distinctArray.push(x));
    return distinctArray;
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export async function files2ServerFormat(files = []) {
    console.log('files2ServerFormat', files);

    let bodies = [];
    await Promise.all(files.map((f, idx) => getBase64(f).then((base64) => (bodies[idx] = base64.substr(base64.indexOf('base64,') + 7)))));
    return files.map((f, idx) => ({
        fileName: f.name,
        fileExtension: f.name.slice(-3),
        fileSize: f.size,
        pageNumber: idx,
        fileBody: bodies[idx],
        url: undefined,
        documentDataType: f.type.startsWith('image')
            ? 'Image'
            : f.type.startsWith('video')
            ? 'Video'
            : f.type === 'application/pdf'
            ? 'Pdf'
            : 'None',
    }));
}

export function jsonParse(str, errValue = undefined) {
    let retval = errValue;
    try {
        retval = JSON.parse(str);
    } catch (err) {
        console.error('json parse error', str, err);
    }
    return retval;
}

export function byProperty(propertyName, descending = false) {
    return (a, b) => (a[propertyName] < b[propertyName] ? 1 : -1) * (descending ? 1 : -1);
}

export function byDateProperty(propertyName, descending = false) {
    return (a, b) => (new Date(a[propertyName]) < new Date(b[propertyName]) ? 1 : -1) * (descending ? 1 : -1);
}

export function byPropertyDesc(propertyName) {
    return byProperty(propertyName, true);
}

export const ASC = false;
export const DESC = true;
export function byProperties(props) {
    const keys = Object.keys(props);
    return (a, b) => {
        for (let i = 0; i < keys.length; i++) {
            const propName = keys[i];
            const descending = props[propName];
            if (a[propName] < b[propName]) return descending ? 1 : -1;
            if (a[propName] > b[propName]) return descending ? -1 : 1;
        }
        return 0;
    };
}

export function object2Array(obj, keyName) {
    return Object.keys(obj).map((k) => ({ ...obj[k], [keyName]: k }));
}

export const addDays = (fromDate, daysCnt) => {
    let result = new Date(fromDate);
    result.setDate(result.getDate() + daysCnt);
    return result;
};

export const addMonths = (fromDate, mnthCnt) => {
    let result = new Date(fromDate);
    result.setMonth(result.getMonth() + mnthCnt);
    return result;
};

export const endOfDate = (d) => {
    let result = zeroTime(addDays(d, 1));
    result.setTime(result.getTime() - 1);
    return result;
};

export const zeroTime = (d) => d && new Date(d.toDateString());

export const today = () => zeroTime(new Date());

export const ellipsis = (str, length = 100) => {
    return str && str.length > length ? str.substr(0, length) + '...' : str;
};

export const getFloat = (prevValue, newValue) => {
    if (!(newValue && newValue.match(/^\-?\d*\.?\d*$/gm))) {
        if (!prevValue || !newValue) {
            return '';
        }
    } else {
        return newValue;
    }

    return prevValue;
};

export const getInteger = (prevValue, newValue) => {
    if (newValue === 0) return 0;

    if (!(newValue && newValue.match(/^\-?\d*$/gm))) {
        if (!prevValue || !newValue) {
            return '';
        }
    } else {
        return newValue;
    }

    return prevValue;
};

export const getBoolean = (value) => {
    if (!value) return false;

    return value.toUpperCase() == 'TRUE';
};

export const get = (array = [], func) => {
    var result;
    Object.values(array).forEach((item) => {
        if (func(item)) {
            result = item;
        }
    });
    return result;
};

export const toUpperCase = (value) => {
    if (!value || typeof value !== 'string') return null;

    return value.toUpperCase();
};

export const dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
};

export const writeLine = (string) => {
    return string + newLine();
};

export const newLine = () => {
    return '\n';
};

export function dynamicSort(property, descending) {
    var sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result;

        if (!descending) {
            result = a[property] - b[property];
        } else {
            result = b[property] - a[property];
        }

        return result * sortOrder;
    };
}

export const toFinanceString = (value, decimalPrecision = 2) => {
    if (!value || value === 0) return 0;

    return parseFloat(value).toLocaleString('ru-RU', { maximumFractionDigits: decimalPrecision });
};

export const isNumber = (value) => {
    return typeof value == 'number';
};

export const isDate = (value) => {
    if (value === undefined) return false;
    var date = moment(value);
    return date.isValid();
};

export const weekDayToStr = (value) => {
    switch (value) {
        case 0: return "Вс";
        case 1: return "Пн";
        case 2: return "Вт";
        case 3: return "Ср";
        case 4: return "Чт";
        case 5: return "Пт";
        case 6: return "Сб";
        case 7: return "Вс";
        default: return "";
    }
}

export const weekDayToStrFull = (value) => {
    switch (value) {
        case 1: return "Понедельник";
        case 2: return "Вторник";
        case 3: return "Среда";
        case 4: return "Четверг";
        case 5: return "Пятница";
        case 6: return "Суббота";
        case 7: return "Воскресенье";
        case 0: return "Воскресенье";
        default: return "";
    }
}

export const fmtDateFull = (value) => {
    return value && value.format("DD.MM.YYYY");
}

export const fmtStringDateFull = (value) => {
    return value && dayjs(value).format("DD.MM.YYYY");
}

export const fmtDateTimeFull = (value) => {
    return value && value.format("DD.MM.YYYY HH:mm:ss");
}

export const fmtTimeShort = (value) => {
    return value && value.format("HH:mm");
}


export const fmtDateShort = (value) => {
    return value && value.format("DD.MM.YY");
}

export const strRandom = (len, chrs) => {
    let str = '';
    for (var i = 0; i < len; i++) {
        var pos = Math.floor(Math.random() * chrs.length);
        str += chrs.substring(pos,pos+1);
    }
    return str;
}

export const orderStatusToStr = (status) => {
    switch(status){
        case IOrderStatus.New: 
            return "Новый";
        case IOrderStatus.Confirmed: 
            return "Подтверждён";
        case IOrderStatus.Cancelled: 
            return "Отменён";
        case IOrderStatus.Paid: 
            return "Оплачен";
        default:
            return "";
    }
}

// Возвращает дату без времени в текущей временной зоне, например "Mon May 06 2024 00:00:00 GMT+0600 (Омск, стандартное время)" или "2024-05-05T18:00:00.000Z"
export const withoutTime = (date) => {
    return date.set('hour', 0).set('minute', 0).set('second', 0);
}

export const withoutTimeZone = (date) => {
    return date.utc(true).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
}

export const setStyleProperty = (propery, color) => {
    if (color) document.documentElement.style.setProperty(propery, color);
};

export const isStringNumber = (str) => {
    return !isNaN(str) && !isNaN(parseFloat(str));
};

export const strToInt = (str) => {
    if (isStringNumber(str)) return Number(str);
    return 0;
}

export const setMetas = (company) => {
    if (company){
        //console.log("setMetas", company);
        company.metaTitle && (document.title = company.metaTitle);
        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription && company.metaDescription && metaDescription.setAttribute('content', company.metaDescription);
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        metaKeywords && company.metaKeywords && metaKeywords.setAttribute('content', company.metaKeywords);
    }
}