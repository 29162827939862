import React from "react";
import './search-filter-date.css';
import { DatePicker } from "antd";

const SearchFilterDate = ({...props}) => {
    const {title, value, onChange, disabledDate} = props;

    return<div className="search-filter-date">
        <img src="/images/calendar16.svg" />
        <div className="search-filter-date-group">
            <div className="search-filter-date-title">{title}</div>
            <div className="search-filter-date-date">
                <DatePicker 
                    format={'DD.MM.YY'}
                    allowClear={false} 
                    variant="borderless"
                    suffixIcon={null}
                    className="search-filter-date-input"
                    value={value}
                    onChange={onChange}
                    disabledDate={disabledDate}
                />
            </div>
        </div>
    </div>
}

export default SearchFilterDate;