import React from "react";
import "./not-found.css";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return<div className="not-found-body">
    <img src="/images/logo.png" className="not-found-logo" alt="logo"/>
    <div className="not-found-title">Указанная страница не найдена</div>
    <Button type="primary" onClick={() => navigate("/")}>Перейти к основному поиску</Button>
  </div>
}

export default NotFound;