import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from "dayjs";
import {LeftOutlined,RightOutlined} from '@ant-design/icons';
import { ISearchAvailRoom, IReservationRoomFilter, IRoomPricePeriod, ICompany } from '@store/entities';
import { serverFetch } from '@src/server';
import CategoryVarDay from './category-var-day';
import CategoryVarItem from './category-var-item';
import { categoryFilterChanged } from '@src/store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { withoutTimeZone } from '@extensions/utils';
import './category-var-days.css'
import { Spinner } from "@src/controls";
import LocalFuncs from "@src/extensions/local-funcs";

const CategoryVarDays = ({...props}) => {
    const { category, onScroll, roomPricePeriods } = props;
    const { companyId } = useParams();
    const [filter] = useState<IReservationRoomFilter>(LocalFuncs.checkFilterPeriod(useAppSelector<IReservationRoomFilter>((s:any) => s.reservationRoomFilter))); // Глобальный фильтр поиска
    const [startDate, setStartDate] = useState<Dayjs>(filter.arrivalDate); // Первая дата просматриваемого интервала - по умолчанию - дата заезда из фильтра
    const [selectedDate, setSelectedDate] = useState<Dayjs>(withoutTimeZone(filter.arrivalDate)); // Выбранная дата заезда
    const [items, setItems] = useState<Array<ISearchAvailRoom>>([]); // Варианты тарифов по выбранному периоду (7 дней) дат заезда и категории
    const srok = filter.departureDate.diff(filter.arrivalDate,'day'); // Срок проживания в сутках для определения даты отъезда при изменении даты заезда
    const [loading, setLoading] = useState<boolean>(true);
    const d = useAppDispatch();
    const [company, setCompany] = useState<ICompany>();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const debug = urlParams.get('debug');

    //console.log(roomPricePeriods);
    // Функция - Получить компанию
    const getCompany = () => {
        serverFetch(`companies/${companyId}`, { method: 'GET', })
            .then((data: ICompany) => { setCompany(data); })
            .catch(ex => console.log('Ошибка получения компании', ex));
    };    

    // Перейти в бронирование выбранного номера и смена фильтра
    const goBook = (pricePeriodId:number,  roomCategoryId?: number, roomPlacingId?: string) => {
        const newArrivalDate = selectedDate;
        const newDepartureDate = selectedDate.add(srok, 'day');
        const newURL = new URLSearchParams();
        pricePeriodId && newURL.set('pricePeriodId', pricePeriodId + "");
        roomCategoryId && newURL.set('roomCategoryId', roomCategoryId + "");
        roomPlacingId && newURL.set('roomPlacingId', roomPlacingId + "");
        filter.orderTimeId && newURL.set('orderTimeId', filter.orderTimeId + "");
        d(categoryFilterChanged({...filter, arrivalDate: newArrivalDate, departureDate: newDepartureDate}));
        let url = `/booking/order/${companyId}?`;
        navigate(url + newURL.toString());
    }

    // #region Загрузка items
    const loadItems = (arrivalDate : Dayjs) => {
        const departureDate = arrivalDate.add(srok, 'day');        
        setLoading(true);
        const calcAsk = filter && {
            adults: filter.adultsQty,
            arrival: withoutTimeZone(arrivalDate),
            companyId,
            departure: withoutTimeZone(departureDate),
            roomCategoryId: category.roomCategoryId,
            tariffId: [],
            tarifs: [],
            userType: 0,
            childrenAges: filter.childAges.slice(0, filter.childsQty),
            promocode: filter.promoCode,
            extraDays: 7,
            orderTimeId: filter.orderTimeId,
            trace: debug && true
        };
        serverFetch(`calc/GetAvailableRooms`, {
            method: 'POST',
            bodyData: calcAsk
        })
        .then(data => {
            let newItems = data.rooms.map((elem: IReservationRoomFilter, id: number) => {
                const elem2 = {...elem, id};
                return elem2
            })
            if (category.roomCategoryExtraId) newItems = newItems.filter((i:any) => i.roomCategoryExtraId == category.roomCategoryExtraId);
            // Установка загруженных вариантов и выбранной даты заезда
            setItems(newItems.sort((a:ISearchAvailRoom, b:ISearchAvailRoom) => a.amountWithDiscount - b.amountWithDiscount));
            setSelectedDate(arrivalDate);
            setLoading(false);
        })
        .catch(e => {
            setLoading(false);
            console.log("Ошибка получения тарифов", e.userMessage);
        });
    }
    // #endregion

    // Загрузка первоначальной информации для заполнения минимальных цен по дням и доступности дней
    useEffect(() => loadItems(filter.arrivalDate), [filter]);
    useEffect(onScroll, [])
    useEffect(getCompany, []);

    // #region Период мин. стоимости
    // Список дней (неделя), начиная с начала просматриваемого периода
    let days = [];
    for (let i = 0; i < 7; i++)
        days.push(startDate.add(i, 'day'));
    const divDays = days.map((day) => {
        const weekDay = day.day() == 0 ? 7 : day.day(); // Конвертация дня недели в понятный формат 1-Пн, 7-Вс
        const dayTarifs = items  // Поиск минимальной стоимости по дню
            .filter((item:ISearchAvailRoom) => dayjs(item.dateBeg).format("DD.MM.YYYY") == day.format("DD.MM.YYYY"))
            .sort((a:ISearchAvailRoom, b:ISearchAvailRoom) => a.amountWithDiscount - b.amountWithDiscount);
        const minAmount = dayTarifs.length == 0 ? 0 : dayTarifs[0].amountWithDiscount;
        return <CategoryVarDay weekDay={weekDay} date={day} amount={minAmount} selected={day.diff(selectedDate, 'day') == 0} 
            onSelect={(id:number) => setSelectedDate(day)} 
            key={weekDay} 
            disabled={items && items.find((i) => dayjs(i.dateBeg).diff(day, 'day') == 0) == null}/>
    })
    // #endregion

    // #region Варианты тарифов по выбранной дате заезда
    // Фильтруем подобранные тарифы по дате
    const filteredItems = items.filter((item:ISearchAvailRoom) => {
        const itemDate = dayjs(item.dateBeg);
        return itemDate.get("year") == selectedDate.get("year") && itemDate.get("month") == selectedDate.get("month") && itemDate.get("date") == selectedDate.get("date")
    });
    
    const divItems = !loading && filteredItems.map((item:ISearchAvailRoom, idx) => {
        const roomPlacing = company?.aleanSystem ? item.aleanRoomPlacing.roomPlacingID : null;
        return <CategoryVarItem key={item.id} item={item} isBest={idx == 0} 
                goBook={() => { goBook(item.roomPricePeriodId, item.roomCategoryId, roomPlacing)}} 
                filter={filter} tariff={roomPricePeriods.find((i:IRoomPricePeriod) => i.roomPricePeriodId == item.roomPricePeriodId)} />
        })
        
    const divLoading = loading && <Spinner />
    const divEmpty = !loading && items.length == 0 && <div>Нет доступных вариантов размещения. Смените категорию или дату заезда</div>
    const arrivalDateCanBeLess = company && startDate.diff(withoutTimeZone(dayjs(company.startBookingDate)), 'days') > 0;
    // #endregion

    // Функция - установка новой даты начала просматриваемого периода
    const onSetNewArrivalDate = async (newDate:Dayjs) => {
        await setStartDate(newDate);
        loadItems(newDate);
    }

    return <div className="category-var-days-container" id="category-var-days-container">
                <div className="category-var-days-category-title">
                    {category.name}
                </div>
                <div className="category-var-days-header">
                    <div className="category-var-days-title">Дата заезда</div>
                    <div className="category-var-days">
                        <div className="category-var-day-arrow">
                            {arrivalDateCanBeLess &&<LeftOutlined onClick={() => onSetNewArrivalDate(startDate.add(-7, 'day'))}/>}
                        </div>
                        {divDays}
                        <div className="category-var-day-arrow">
                            <RightOutlined onClick={() => onSetNewArrivalDate(startDate.add(7, 'day'))}/>
                        </div>
                    </div>
                    <div className="category-var-days-end">
                        <div className="category-var-days-title">{`${selectedDate.format("DD.MM.YYYY")} - ${selectedDate.add(srok, 'day').format("DD.MM.YYYY")}`}</div>
                        <div className="category-var-days-title">{`${filter.adultsQty} взрослых + ${filter.childsQty} детей`}</div>
                    </div>
                </div>
                <div className="category-var-days-items">
                    {divItems}
                    {divLoading}
                    {divEmpty}
                </div>
            </div>
}

export default CategoryVarDays;