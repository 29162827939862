import React, { useEffect, useState } from "react";
import './account.css';
import { Button, DatePicker, Form, Input, Modal, Select, Space, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { serverFetch } from "@src/server";
import { userLoaded } from "@src/store/actions";
import dayjs from "dayjs";

const Account = ({...props}) => {
    const d = useDispatch();
    const { onClose } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const user = useSelector((s) => s.user);
    const [tourist, setTourist] = useState();

    useEffect(() => {
        setLoading(true);
        serverFetch('tourist', {method: 'GET'})
            .then((data) => {
                if (data.birthdayDate) data.birthdayDate = dayjs(data.birthdayDate+".000Z");
                setTourist(data);
                form.setFieldsValue(data);
                setLoading(false);
            })
            .catch(() => {
                message.error("Ошибка получения данных туриста");
                setLoading(false);
            });
    }, [])

    const onValuesChange = (value) => {
        console.log(value);
        setTourist({ ...tourist, ...value });
    };

    const onFinish = () => {
        setLoading(true);
        serverFetch('tourist', {method: 'POST', bodyData: tourist})
            .then(() => {
                d(userLoaded({...user, touristSurname: tourist.surname, touristName: tourist.name, touristPatronymic: tourist.patronymic, touristPhone: tourist.phone}));
                onClose();
            })
            .catch(() => {
                message.error('Ошибка сохранения данных пользователя');
                setLoading(false);
            })
        }

    return <Modal open={true}
            forceRender
            title="Мои данные"
            closable={false}
            closeOnOutsideClick={false}
            footer={null}
            width={540}
            onCancel={() => onClose()}
            bodyStyle={{ margin: '-4px 16px 0' }}
        >
            <Form
                colon={false}
                form={form}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
            >
                <Form.Item label='Эл.почта' name='email'><Input disabled/></Form.Item>
                <Form.Item label='Фамилия' name='surname'><Input /></Form.Item>
                <Form.Item label='Имя' name='name'><Input /></Form.Item>
                <Form.Item label='Отчетство' name='patronymic'><Input /></Form.Item>
                <Form.Item label='Телефон' name='phone'><Input /></Form.Item>
                <Form.Item label='Пол' name='gender'><Select options={[{value: 0, label: "Муж."}, {value: 1, label: "Жен."}]} /></Form.Item>
                <Form.Item label='Адрес' name='address'><Input /></Form.Item>
                <Form.Item label='Дата рождения' name='birthdayDate'><DatePicker format="DD.MM.YYYY"/></Form.Item>
                <Form.Item label=" ">
                    <Space>
                        <Button className='auth-btn' size='middle' onClick={() => onClose()} disabled={loading}>Назад</Button>
                        <Button className='auth-btn' size='middle' loading={loading} htmlType='submit' >Сохранить</Button>
                    </Space>
                </Form.Item>
                <label className='prompt'>Нажимая на кнопку "Сохранить", вы даете согласие на обработку персональных данных и соглашаетесь c условиями и правилами сервиса</label>
            </Form>

        </Modal>
}

export default Account;