import React from "react";
import { serverFetch } from "@src/server";
import { ICompany, IOptionalService, IRoomCategory } from "@src/store/entities";
import { message } from "antd";

const DataOpers = {
        getCompany: (companyId: string | undefined, setCompany:React.Dispatch<React.SetStateAction<ICompany | undefined>>) => {
                serverFetch(`companies/${companyId}`, { method: 'GET' })
                        .then((data:ICompany) => setCompany(data))
                        .catch(e => message.error(`Ошибка получения компании ${e.userMessage || ''} `));
        },
        getOptionalServices: (pricePeriodId: number, setData:React.Dispatch<React.SetStateAction<IOptionalService[] | undefined>>, title:string | undefined) => {
                pricePeriodId && serverFetch(`Booking/GetTariffOptionaServices/${pricePeriodId}`, { method: 'GET' })
                        .then(data => setData(data))
                        .catch(e => message.error(`${title || ""} ошибка ${e.userMessage || ''} `));
        },
        getCategories: (companyId: string | undefined, setData:React.Dispatch<React.SetStateAction<IRoomCategory[]>>, title:string | undefined) => {
                serverFetch(`booking/GetCategories/${companyId}`, { method: 'GET' })
                    .then((data) => setData(data))
                    .catch(e => message.error(`${title || ""} ошибка ${e.userMessage || ''} `));
        }
}

export default DataOpers;
