import React, { useEffect, useState } from "react";
import './sales.css';
import MainFooter from "../main/main-footer";
import MainHeader from "../main/main-header";
import { serverFetch } from "@src/server";
import { Button, Space, Table, message } from "antd";
import { fmtStringDateFull, orderStatusToStr } from "@src/extensions/utils";
import { useSelector } from "react-redux";

const Sales = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = useSelector((s) => s.user);

    const getOrders = () => {
        if (user){
            setLoading(true);
            serverFetch(`booking`, { method: 'GET' })
                .then((data) => {
                    setOrders(data);
                    setLoading(false);
                })
                .catch((ex) => {
                    message.error("Ошибка получения путевок");
                    setLoading(false);
                });
        }
    }

    useEffect(getOrders, [user]);

    const renderStatus = (order) => {
        return<div className="sales-row">{orderStatusToStr(order?.orderStatus)}</div>
    }

    const renderCell = (value) => <div className="sales-row">{value}</div>

    const columns = [
        { title: "#", dataIndex: "orderId", align: "center", render: renderCell},
        { title: "Статус", dataIndex: "syncStatus", align: "left", render: (_,record) => renderStatus(record)},
        { title: "Туробъект", dataIndex: "companyName", align: "left", render: renderCell},
        { title: "Категория", dataIndex: "roomCategoryName", align: "left", render: renderCell},
        { title: "Тариф", dataIndex: "roomPricePeriodName", align: "left", render: renderCell},
        { title: 'Заезд', dataIndex: "arrivalDate", align: "center", render: (_, record) => <div className="sales-row">{fmtStringDateFull(record.arrivalDate)}</div>},
        { title: 'Выезд', dataIndex: "departureDate", align: "center", render: (_, record) => <div className="sales-row">{fmtStringDateFull(record.departureDate)}</div>},
        { title: "Турист", dataIndex: "touristName", align: "left", render: renderCell},
        { title: "Стоимость", dataIndex: "price", align: "right", render: renderCell},
    ]

    return<>
    <MainHeader />
    <Space className="sales-pnl"><div><strong>Путевки</strong></div><Button onClick={getOrders}>Обновить</Button></Space>
    <Table columns={columns} dataSource={orders} 
        onRow={(record) => {return {onClick: (event) => window.open(`/booking/${record.orderId}_${record.orderNumber}`, '_blank')};}}
        loading={loading}
    />
    <MainFooter hidden={false}/>
    </>
}

export default Sales;