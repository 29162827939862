import React from "react";
import './transaction.css';
import { fmtDateTimeFull } from "@src/extensions/utils";
import dayjs from "dayjs";
import { Button } from "antd";
import { useWindowWidth } from "@react-hook/window-size";

const Transaction = ({...props}) => {
    const onlyWidth = useWindowWidth();
    const { transaction } = props;

    return<>
        <div className="transaction-item">
            <div>{transaction.operationType === 0 ? 'Оплата' : 'Возврат' }</div>
            <div>{fmtDateTimeFull(dayjs(transaction.createdDateTime))}</div>
            <div>{transaction.quantity} руб.</div>
            <div>{onlyWidth >= 750 && transaction.fiscalReceiptUrl && <Button><a href={transaction.fiscalReceiptUrl} target="_blank" rel="noreferrer"><strong>Скачать чек</strong></a></Button>}</div>
        </div>
        {onlyWidth < 750 && <div className="transaction-item"><Button><a href={transaction.fiscalReceiptUrl} target="_blank" rel="noreferrer"><strong>Скачать чек</strong></a></Button></div>}
    </>
}

export default Transaction;