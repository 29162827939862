import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import CookieConsent from "react-cookie-consent";
import './index.css';

const MainLayout = () => {
    const { Content } = Layout;
    const isFrame = window.self !== window.top;

    return (
        <Layout style={{ height: '100vh', minHeight: '100vh' }}>
            <Layout>
                <Content className='main-content' id='main-page'>
                    <Outlet />
                </Content>
            </Layout>
            {isFrame || <CookieConsent buttonText="Принять" location="top">Мы используем файлы cookie чтобы обеспечить Вам наилучшую работу на нашем веб-сайте. Продолжая использовать этот сайт, Вы даете согласие на использование файлов cookie</CookieConsent>}
        </Layout>
    );
};

export default MainLayout;