import { IReservationRoomFilter } from "@src/store/entities";
import dayjs from "dayjs";
import { withoutTime } from "./utils";

const LocalFuncs = {
    // Проверка что текущий фильтр валидный по датам и если нет то исправляем фильтр
    checkFilterPeriod: (filter:IReservationRoomFilter) => {
        const currentDate = withoutTime(dayjs());
        if (!filter) 
            return {
                arrivalDate: currentDate,
                departureDate: currentDate.add(1, 'day'),
                companyId: 0,
                adultsQty: 2,
                childsQty: 0,
                selectedTariff: 0,
                childAges: [],
                filterChanged: true,
            }

        const newFilter = {...filter, arrivalDate: dayjs(filter.arrivalDate), departureDate: dayjs(filter.departureDate), filterChanged: false };
        // Проверяем что некорректные даты
        if (!newFilter.arrivalDate.isValid() || !newFilter.departureDate.isValid()) {
            console.log("Некорректные даты фильтра", newFilter.arrivalDate, newFilter.departureDate);
            newFilter.arrivalDate = currentDate;
            newFilter.departureDate = newFilter.arrivalDate.add(1, 'day');
            newFilter.filterChanged = true;
        }
        // Убираем время
        newFilter.arrivalDate = withoutTime(newFilter.arrivalDate);
        newFilter.departureDate = withoutTime(newFilter.departureDate);
        // Проверяем что дата выезда не раньше даты заезда
        if (newFilter.arrivalDate > newFilter.departureDate) {
            console.log("Дата заезда позже даты выезда");
            newFilter.departureDate = newFilter.arrivalDate.add(1, 'day');
            newFilter.filterChanged = true;
        }
        // Проверяем что дата заезда не раньше текущей даты
        if (newFilter.arrivalDate.isBefore(dayjs(), 'day')) {
            //console.log("Дата заезда раньше текущей даты");
            const srok = newFilter.departureDate.diff(newFilter.arrivalDate, 'day');
            newFilter.arrivalDate = currentDate;
            newFilter.departureDate = newFilter.arrivalDate.add(srok, 'day');
            newFilter.filterChanged = true;
        }
        // Корректировка по детям
        if (newFilter.childAges.length != newFilter.childsQty) {
            console.log("Корректировка количества детей");
            newFilter.childsQty = newFilter.childAges.length;
            newFilter.filterChanged = true;
        }
        if (newFilter.filterChanged){
            //console.log("Фильтр необходимо обновить", newFilter);
            newFilter.adultsQty = 1;
        }
        return newFilter;
    }
}

export default LocalFuncs;