import { Modal } from "antd";
import React, { useState } from "react";
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import './maps.css';

const Maps = ({...props}) => {
    const { onClose, coords, onBook } = props;
    const [ymaps, setYmaps] = useState();
    const template = ymaps ? ymaps.templateLayoutFactory.createClass('<div class="map-bg map"><div class="map-title map">$[properties.itemPrice]</div><div class="map-text map">$[properties.itemName]</div></div>') : null;
    const createTemplateLayoutFactory = (ymaps2) => ymaps2 && !ymaps && setYmaps(ymaps2)
    const handleClick = (e) => onBook(e.originalEvent.target.properties._data.companyId)
    const divPlaces = coords.map(item => <Placemark 
        geometry={{ type: 'Point', coordinates: [item.x, item.y], }}
        key={item.name}
        properties={{
            itemPrice: item.price,
            itemName: item.name,
            companyId: item.companyId,
        }}
        options={{
            iconLayout: template,
            iconContentSize: [100,100],
            iconContentOffset: [20, 20],
            iconShape: { type: 'Rectangle', coordinates: [[0, 0], [100, 50]], onClick: (e) => console(e) }
        }}    
        onClick={handleClick}    
    />);

    return<Modal open={true} forceRender title=""  footer={null} width="90%" onCancel={onClose} >
        <YMaps style={{width: "100%", height: "100%"}} query={{apikey: process.env.REACT_APP_YANDEX_MAP}} >
            <Map defaultState={{ center: [coords[0].x, coords[0].y], zoom: 9 }} width="100%" height={window.innerHeight*0.8} modules={["templateLayoutFactory", "layout.ImageWithContent"]}
              onLoad={createTemplateLayoutFactory} >
                {divPlaces}
            </Map>
        </YMaps>
    </Modal>
}

export default Maps;