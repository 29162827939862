import LocalFuncs from "@src/extensions/local-funcs";
import { fmtDateFull, weekDayToStr } from "@src/extensions/utils";
import { Radio, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AleanPlaces from "../search-categories/alean-places";

const BookingOrderFloat = ({...props}) => {
    const { currentTariff, orderData, optionalProffitSerives, selectedPit, setSelectedPit, optionalServices, company, booking, setOrderData } = props;
    const [filter, _] = useState(LocalFuncs.checkFilterPeriod(useSelector((s) => s.reservationRoomFilter)));
    const { adultsQty, arrivalDate, departureDate, orderTimeId, childsQty } = filter;
    const orderTime = company?.orderTimes?.find((d) => d.orderTimeId == orderTimeId);
    const addDay = orderTime && orderTime.isFullDay ? 1 : 0;

    //console.log(currentTariff);
    //console.log(optionalProffitSerives);
    const divOptionalServices = optionalProffitSerives && optionalProffitSerives.length > 1 && (
        <div>
            <div className='booking-order-float-item booking-order-float-caption'>Питание</div>
            <Radio.Group value={selectedPit} onChange={(e) => setSelectedPit(e.target.value)}>
                <Space direction='vertical'>
                    {optionalProffitSerives.map((i) => (
                        <Radio value={i.id} key={i.id}>
                            <div className='booking-order-float-item'>{i.name}</div>
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </div>
    );

    const divIncluded =
        optionalServices &&
        optionalServices.map((x) => (
            <div className='booking-order-float-item' key={x.baseOptionalServiceId}>
                {x.name}
            </div>
        ));

    return<div className='booking-order-float'>
        <div className='reserve-section-title'>Ваша заявка на бронирование</div>
        <div className='booking-order-float-item booking-order-float-caption'>Размещение</div>
        <div className='booking-order-float-item'>
            <div className='booking-order-float-label'>Заезд</div>
            <div>
                {weekDayToStr(dayjs(arrivalDate).day())} {fmtDateFull(dayjs(arrivalDate))}{' '}
                {orderTime && orderTime.arrivalTime.toString().substr(0, 5)}
                {currentTariff && currentTariff.aleanVisit && currentTariff.aleanVisit.begin && dayjs(currentTariff.aleanVisit.begin).format("HH:mm")}
            </div>
        </div>
        <div className='booking-order-float-item'>
            <div className='booking-order-float-label'>Выезд</div>
            <div>
                {weekDayToStr(dayjs(departureDate).day())} {fmtDateFull(dayjs(departureDate))}{' '}
                {orderTime && orderTime.departureTime.toString().substr(0, 5)}
                {currentTariff && currentTariff.aleanVisit && currentTariff.aleanVisit.end && dayjs(currentTariff.aleanVisit.end).format("HH:mm")}
            </div>
        </div>
        <div className='booking-order-float-item'>
            <div className='booking-order-float-label'>Срок</div>
            <div>{dayjs(departureDate).diff(dayjs(arrivalDate), 'day', true) + addDay} суток</div>
        </div>

        <div className='booking-order-float-item booking-order-float-caption'>Гости</div>
        
        {currentTariff && currentTariff.aleanRoomPlacing 
            ? <div className='booking-order-float-item'><AleanPlaces aleanRoomPlacing={currentTariff.aleanRoomPlacing} aleanTouristTypes={currentTariff.aleanTouristTypes}/></div>
            : <div className='booking-order-float-item'>{adultsQty} взрослых {childsQty > 0 && `+ ${childsQty} детей`}</div>
        }

        <div className='booking-order-float-item booking-order-float-caption'>Тариф</div>
        <div className='booking-order-float-item'>{currentTariff && currentTariff.tariffName}</div>
        <div className='booking-order-float-item'>
            <div className='booking-order-float-label'>Включено</div>
        </div>
        {divIncluded}
        {divOptionalServices}
        <div className='booking-order-float-item booking-order-float-caption'>Пожелания</div>
        <TextArea
            autoSize={{ minRows: 4 }}
            disabled={booking}
            value={orderData.extraComment}
            onChange={(e) => setOrderData({ ...orderData, extraComment: e.target.value })}
            style={{ marginTop: 4 }}
        />
        <div className='reserver-hotel-tariff-price'>{currentTariff && currentTariff.amountWithDiscount.toLocaleString('ru-RU')} ₽</div>
    </div>
}

export default BookingOrderFloat;