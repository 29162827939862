import { AnyAction } from 'redux';
import { USER_LOADED, CURRENT_MENY_ITEM, CATEGORY_FILTER_CHANGED } from './actions';
import { IReservationRoomFilter } from '@src/store/entities';
import dayjs from 'dayjs';

export interface IReduxState {
    user?: any;
    currentMenuItem: string;
    reservationRoomFilter: IReservationRoomFilter | undefined;
}

export const initialState: IReduxState = {
    user: null,
    currentMenuItem: 'orders',
    reservationRoomFilter: undefined,
};

export const reducer = (state: any, action: AnyAction) => {
    //console.log("red", state, action);
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                user: action.payload,
            };

        case CURRENT_MENY_ITEM:
            return {
                ...state,
                currentMenuItem: action.payload,
            };

        case CATEGORY_FILTER_CHANGED:
            const newPayload = {...action.payload, arrivalDate: dayjs(action.payload.arrivalDate).format("YYYY-MM-DD"), departureDate: dayjs(action.payload.departureDate).format("YYYY-MM-DD")}
            return {
                ...state,
                reservationRoomFilter: newPayload,
            };
        default:
            return state;
    }
};
