import React from "react";
import './search-region-toggle.css';

const SearchRegionToggle = ({...props}) => {
    const {collapsed, onCollaps, qty} = props;

    const divImg = collapsed ? <img src="/images/down.svg"/> : <img src="/images/up.svg"/>
    return qty > 0 && <div onClick={onCollaps} className="search-region-toggle">
        {divImg}
        {collapsed ? `Еще ${qty} варианта` : 'Свернуть'}
    </div>

}

export default SearchRegionToggle;